.ant-modal {
  max-height: 100vh;
  top: 0;
  width: 480px !important;
  margin: 0;

  .ant-modal-content {
    .ant-modal-body {
      height: 100%;
      overflow-y: scroll;
      padding: 0 24px;
      .ant-tabs-top {
        height: auto !important;
      }
    }
    .ant-modal-body::-webkit-scrollbar {
      display: none;
    }
  }

  .anticon {
    vertical-align: 0 !important;
  }

  .ant-modal-body {
    font-family: 'Lato', sans-serif;
    .ant-card {
      border-radius: 0;
      box-shadow: none;
      .ant-card-head {
        border-radius: 0;
        background: none;
        .ant-card-head-title {
          @include heading($heading-font-size, $heading-line-height);
        }
      }
      .ant-form-item.upload-wrapper.right {
        .ant-form-item-control {
          flex-direction: row;
          justify-content: center;
        }
      }
      .ant-form-item-control-input-content {
        text-align: left;
      }
    }

    .ant-btn-primary {
      width: 50%;
      text-transform: none;
    }

    .ant-btn-secondary {
      border: none;
      box-shadow: none;
    }

    .dashboard .card-wrapper .ant-card {
      width: 100%;
    }
  }
}

#upload-box-avatar-0 {
  margin: 0 !important;
  border: none !important;
  border-radius: $round-img-border-radius !important;
  overflow: hidden;

  img {
    //border-radius: 50% !important;
    max-width: max-content !important;
    height: 100%;
    width: auto;
  }
  div {
    border-radius: 50% !important;
  }
  .anticon .anticon-edit svg {
    color: $wht;
  }
}

.gallery-modal {
  //top: 320px!important;
  position: relative !important;
  margin: 0 auto !important;
  width: fit-content !important;
  min-width: 50%;
  max-width: 1000px;

  .ant-modal-content {
    border-radius: 4px;
    height: auto;
    max-height: none;
    .ant-modal-body {
      height: auto;
      overflow: hidden;
      padding: 24px;
    }
  }
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.1);
}

.deleteModal {
  .ant-modal {
    width: 600px !important;
    height: 200px !important;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .ant-modal-footer {
      border-top: none;
    }
  }
  p:first-child {
    font-size: 18px;
  }
  p:nth-child(2) {
    font-weight: bold;
    font-size: 22px;
  }
}

.deleteModal .ant-modal-footer .ant-btn:hover {
  
    background: #f0f2f5;
   
 }

 .deleteModal .ant-modal-footer .ant-btn-primary:hover {
  
  background: #1138c2;
 
}

// edituser ok button
.modal .ant-card .ant-card-body .ant-btn-primary {
  max-width: 50% !important;
  // padding-left: 40px !important;
  text-transform: none;
}