th {
  text-align: center;
}

th,
td {
  font-size: 0.875em;
}

> th > td:first-child {
  width: 100px;
}

.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  // border: 3px solid $primary-color; //okvir celog polja
  border-spacing: 0;
  border-radius: 2px;

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    padding: 13px 15px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ffffff;
  }

  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ffffff;
    text-transform: uppercase;
  }

  > caption + thead > tr:first-child > th,
  > colgroup + thead > tr:first-child > th,
  > thead:first-child > tr:first-child > th,
  > caption + thead > tr:first-child > td,
  > colgroup + thead > tr:first-child > td,
  > thead:first-child > tr:first-child > td {
    border-top: 0;
  }

  > tbody {
    + tbody {
      border-top: 2px solid #ffffff;
    }

    tr {
      &:hover {
        > td {
          background-color: #f0f6f8;
        }
      }
    }
  }
}

.ant-table-thead > tr > th {
  text-align: center;
  font-size: 15px;
}
.ant-table-tbody > tr > td {
  font-size: 14px;
}

.ant-table-tbody > tr:hover > td {
  background-color: $secondary-color-hover !important;
}

.table > thead > tr:first-child {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.table > thead > tr:first-child > th:first-child {
  border-top-left-radius: 2px;
}
.table > thead > tr:first-child > th:last-child {
  border-top-right-radius: 2px;
}
.table > tfoot > tr:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.table > tfoot > tr:last-child > th:first-child {
  border-bottom-left-radius: 2px;
}
.table > tfoot > tr:last-child > th:last-child {
  border-bottom-right-radius: 2px;
}
.table > tbody > tr:last-child > th:first-child,
.table > tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 2px;
}
.table > tbody > tr:last-child > th:last-child,
.table > tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 2px;
}
.table > tfoot + tbody > tr:last-child > th:first-child,
.table > tfoot + tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 0;
}
.table > tfoot + tbody > tr:last-child > th:last-child,
.table > tfoot + tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 0;
}

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ffffff;

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    border: 1px solid #ffffff;
  }

  > thead > tr > th,
  > thead > tr > td {
    border-bottom-width: 2px;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfd;
}
.table-hover > tbody > tr:hover {
  background-color: #e7e9ee;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0.01%;

  @include media('<md') {
    width: 100%;
    margin-bottom: 12.75px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ffffff;
  }

  th,
  td {
    @include media('<md') {
      white-space: nowrap;
    }
  }

  > .table-bordered {
    @include media('<md') {
      border: 0;

      th {
        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }
}

/* colors */
.table > .success > tr > th,
.table > .success > tr > td {
  background-color: #505b72;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}
.table > .primary > tr > th,
.table > .primary > tr > td {
  background-color: #2574ab;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}
.table > .success-2 > tr > th,
.table > .success-2 > tr > td {
  background-color: #4f66a0;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}
.table > .danger > tr > th,
.table > .danger > tr > td {
  background-color: #d9534f;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}
.table > .success > tr:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.table > .success > tr:first-child > th:first-child,
.table > .success > tr:first-child > td:first-child {
  border-top-left-radius: 0;
}
.table > .success > tr:first-child > th:last-child,
.table > .success > tr:first-child > td:last-child {
  border-top-right-radius: 0;
}

.table-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;

  a {
    display: inline-flex;
    width: 20px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  i {
    vertical-align: baseline;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary-color;
}

.ant-tabs-tab:hover {
  color: #4f66a0;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: $secondary-color;
}

.ant-tabs-ink-bar {
  background: $primary-color;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: $secondary-color;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #4f66a0;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-range .ant-picker-active-bar {
  background: #4f66a0;
}

td.ant-table-column-sort {
  background: initial;
}

.actions-block {
  background-color: $wht !important;
  min-height: 100px;
  border-radius: $table-border-radius-top;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table {
  border-radius: $table-border-radius;
  box-shadow: $table-box-shadow;
  overflow: hidden !important;
  background-color: $wht;
  min-height: calc(100vh - 122px);

  @media (min-width: 992px) {
    min-height: calc(100vh - 60px);
  }
}

.ant-table-filter-dropdown {
  padding: 10px;
  border-radius: 4px;

  .ant-btn {
    @include smallbtn;
  }
}

.lock:hover .icon-unlock,
.lock .icon-lock {
  display: none;
}
.lock:hover .icon-lock {
  display: inline;
}
.lock:hover svg {
  color: $primary-color !important;
}

.invoices .ant-table-bordered .ant-table-thead > tr > th {
  padding-top: 35px !important;
  padding-bottom: 15px !important;
}
.newsletters .ant-table-bordered .ant-table-thead > tr > th {
  padding-top: 35px !important;
  padding-bottom: 15px !important;
}

.search {
  min-height: 44px;
  border-radius: 90px;
  padding: 0 20px;
}

.actions-block {
  & .left-side {
    display: flex;
  }
}

.table-orders-okvir {
  min-height: 100%;
  background-color: $wht;
  border-radius: 10px;
}

table thead tr th {
  border-top: 0 !important;
}

.panel-body {
  margin-top: 15px;
}

// .ant-table-tbody {
//   & tr {
//     &:first-child {
//       & td {
//         display: none;
//       }
//     }
//   }
// }

.ant-table-pagination.ant-pagination {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-bottom: 15px !important;
  padding-top: 15px !important;
  border-bottom: 1px solid #e8e8e8 !important;

  & li {
    width: 28px !important;
    height: 28px !important;
    line-height: 28px !important;
  }
}

.special-data-table {
  table {
    & .ant-table-thead {
      & tr {
        & th,
        & td {
          &:nth-child(1) {
            width: 100px;
            text-align: center;
          }
          &:nth-child(2) {
            width: auto;
            text-align: center;
          }
          &:nth-child(3) {
            width: 300px;
            text-align: center;
          }
          &:nth-child(4) {
            width: 150px;
            text-align: center !important;
          }
          &:nth-child(5) {
            width: 100px;
            text-align: center !important;
          }
          &:nth-child(6) {
            width: 100px;
            text-align: center;
          }
          &:last-child {
            width: 100px;
            text-align: center;
          }
        }
      }
    }
  }
}

// ORDER TABELA
.table-post-okvir {
  margin-left: 20px;
}

// .table-post-okvir .ant-table-thead > tr > th {
//   text-align: center;
//   font-size: 15px;
// }

// .table-post-okvir .ant-table-tbody > tr > td {
//   text-align: center;
//   font-size: 14px;
// }

// .table-post-okvir .ant-table-tbody > tr > td:nth-child(1) {
//   width: 200px;
// }

// .table-post-okvir .ant-table-tbody > tr > td:nth-child(2) {
//   width: 200px;
// }

// .table-post-okvir .ant-table-tbody > tr > td:nth-child(4) {
//   width: 220px;
// }

// .table-post-okvir .ant-table-tbody > tr > td:nth-child(5) {
//   width: 200px;
// }

// .table-post-okvir .ant-table-tbody > tr > td:nth-child(6) {
//   width: 120px;
// }

// .table-post-okvir .ant-table-tbody > tr > td:nth-child(7) {
//   width: 120px;
// }

// .table-post-okvir .ant-table-tbody > tr > td:nth-child(8) {
//   width: 100px;
// }

.table-orders-okvir h1 {
  font-size: 24px;
  font-weight: bold;
  padding-top: 50px;
  padding-left: 50px;
  margin-bottom: -50px;
}

// USER TABELA
.table-user-okvir .ant-table-thead > tr > th {
  text-align: center;
  font-size: 15px;
}

.table-user-okvir .ant-table-tbody > tr > td {
  text-align: center;
  font-size: 14px;
}

// .table-user-okvir .ant-table-tbody > tr > td:nth-child(1) {
//   width: 200px;
// }

// .table-user-okvir .ant-table-tbody > tr > td:nth-child(2) {
//   width: 200px;
// }

// .table-user-okvir .ant-table-tbody > tr > td:nth-child(4) {
//   width: 5%;
// }

// .table-user-okvir .ant-table-tbody > tr > td:nth-child(5) {
//   width: 5%;
// }

// .table-user-okvir .ant-table-tbody > tr > td:nth-child(6) {
//   width: 150px;
// }

// .table-user-okvir .ant-table-tbody > tr > td:nth-child(7) {
//   width: 6%;
// }
// .table-user-okvir .ant-table-tbody > tr > td:nth-child(8) {
//   width: 20%;
// }

// .table-user-okvir .ant-table-tbody > tr > td:nth-child(9) {
//   width: 160px;
// }

// .table-user-okvir .ant-table-tbody > tr > td:nth-child(10) {
//   width: 100px;
// }

// .table-user-okvir .ant-table-tbody > tr > td:nth-child(11) {
//   width: 100px;
// }

// .table-user-okvir .ant-table-tbody > tr > td:nth-child(12) {
//   width: 100px;
// }

// USER TABELA - NOVA ANTD TABELA PROIZVODA
.main-product-table .ant-table-thead > tr > th {
  text-align: center;
  font-size: 15px;
}

.main-product-table .ant-table-thead > tr > td:nth-child(1) {
  width: 150px;
}

.main-product-table .ant-table-thead > tr > td:nth-child(2) {
  width: 150px;
}

.main-product-table .ant-table-thead > tr > td:nth-child(3) {
  width: 80px;
}

.main-product-table .ant-table-thead > tr > td:nth-child(4) {
  width: 50px;
}

.main-product-table .ant-table-thead > tr > td:nth-child(5) {
  width: 80px;
}

.main-product-table .ant-table-thead > tr > td:nth-child(6) {
  width: 80px;
}

.main-product-table .ant-table-thead > tr > td:nth-child(7) {
  width: 50px;
}

.main-product-table .ant-table-thead > tr > td:nth-child(8) {
  width: 50px;
}

.main-product-table .ant-table-thead > tr > td:nth-child(9) {
  // width: 100px;
}

.main-product-table .ant-table-thead > tr > td:nth-child(10) {
  // width: 100px;
}

.main-product-table .ant-table-tbody > tr > td {
  text-align: center;
  font-size: 12px;
}

// .main-product-table .ant-table-tbody > tr > td:nth-child(1) {
// width: 150px;
// }

// .main-product-table .ant-table-tbody > tr > td:nth-child(2) {
// width: 150px;
// }

// .main-product-table .ant-table-tbody > tr > td:nth-child(3) {
// width: 80px;
// }

// .main-product-table .ant-table-tbody > tr > td:nth-child(4) {
// width: 50px;
// }

// .main-product-table .ant-table-tbody > tr > td:nth-child(5) {
// width: 80px;
// }

// .main-product-table .ant-table-tbody > tr > td:nth-child(6) {
// width: 80px;
// }

// .main-product-table .ant-table-tbody > tr > td:nth-child(7) {
//   width: 5%;
// }

// .main-product-table .ant-table-tbody > tr > td:nth-child(8) {
// width: 50px;
// }

// .main-product-table .ant-table-tbody > tr > td:nth-child(9) {
// width: 100px;
// }

// .main-product-table .ant-table-tbody > tr > td:nth-child(10) {
// width: 100px;
// }

.ant-table-filter-column {
  // flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.ant-table-filter-trigger {
  padding-top: 12px;
  padding-bottom: 12px;
}

// .ant-dropdown-trigger.ant-dropdown-open>span {
//   background-color: #d9534f;
// }

.ant-dropdown-trigger.active > span > svg {
  filter: invert(27%) sepia(8%) saturate(4279%) hue-rotate(150deg) brightness(97%) contrast(85%);
}

.table-striped-rows {
  max-width: 50%;
}

.table-row-dark {
  background-color: #f0f0f0 !important;
}
