.ant-pagination {
  &-item-active {
    border-color: $primary-color;
    color: #fff;
    &:hover {
      // border-color: darken($c-primary,5);
      background: $secondary-color;
      border-color: $secondary-color;

      a,
      a:visited {
        color: darken($primary-color, 5);
      }
    }

    a,
    a:visited {
      // color: $c-primary;
      color: white; // boja slova aktivna strana paginacija

      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }

  // &-options-size-changer {
  //     &.ant-select {
  //         margin-right: 0;
  //     }
  // }

  li {
    vertical-align: bottom;
  }

  .anticon {
    vertical-align: middle;
  }
}

.ant-table-pagination {
  &.ant-pagination {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
